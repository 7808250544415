import apiClient from './apiMainClient'

/**
 * Obtém uma lista de telemedicinas com parâmetros opcionais.
 *
 * @param {Object} params - Parâmetros de consulta para a requisição.
 * @param {number} [params.page] - Número da página para paginação.
 * @param {number} [params.limit] - Número de usuários por página.
 * @param {string} [params.sort] - Campo para ordenação.
 * @returns {Promise<Object[]>} - Lista de telemedicinas.
 */
export const getTelemedicines = async (params = {}) => {
  try {
    const response = await apiClient.get('/telemedicines', { params })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Obtem todas as telemedicinas ativas para autocmplete.
 */
export const getTelemedicinesAll = async () => {
  try {
    const response = await apiClient.get('/telemedicines/all')
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getTelemedicineById = async (id) => {
  try {
    const response = await apiClient.get(`/telemedicines/${id}`)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createTelemedicine = async (data) => {
  try {
    const response = await apiClient.post('/telemedicines', data)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateTelemedicine = async (id, data) => {
  try {
    const response = await apiClient.put(`/telemedicines/${id}`, data)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Obtem a telemedicina de acordo com o contexto:
 *  - se logado a API verifica a user.telemedicineId;
 *  - não logado a API verifica o domínio da requisição;
 */
export const getTelemedicineCurrent = async (id) => {
  try {
    const response = await apiClient.get(`/telemedicines/current`)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
