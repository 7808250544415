import React, { useState } from "react"
import Draggable from "react-draggable"
import { Resizable } from "react-resizable"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles'
import "react-resizable/css/styles.css"
import "./floating-window.scss"
// icons
import { CameraIcon, WindowMaximizeIcon, WindowRestoreIcon, WindowMinimizeIcon, CloseIcon } from "../../Icons"

const FloatingWindow = ({
  title,
  children,
  startWidth = 650,
  startHeight = 400,
  startX = 0,
  startY = 0,
  onClose
 }) => {

  const theme = useTheme()
  const darkTheme = createTheme({
    palette: { mode: 'dark' },
  })

  const [isMaximized, setIsMaximized] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const [windowSize, setWindowSize] = useState({ width: startWidth, height: startHeight })
  const [windowPosition, setWindowPosition] = useState({ x: startX, y: startY })
  const [savedSize, setSavedSize] = useState({ width: startWidth, height: startHeight }) // Estado para salvar o tamanho original
  const [savedPosition, setSavedPosition] = useState({ x: startX, y: startY }) // Estado para salvar a posição original
  const [isResizing, setIsResizing] = useState(false)
  const [isDraging, setIsDraging] = useState(false)

  const toggleMaximize = () => {
    if (isMaximized) {
      // Restaurar o tamanho e a posição original
      setWindowSize(savedSize)
      setWindowPosition(savedPosition)
    } else {
      // Salvar o tamanho e a posição atuais antes de maximizar
      setSavedSize(windowSize)
      setSavedPosition(windowPosition)
      // Maximizar para ocupar toda a viewport
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
      setWindowPosition({ x: 0, y: 0 })
    }
    setIsMaximized(!isMaximized)
  }

  const toggleMinimize = () => {
    if (isMinimized) {
      setWindowSize(savedSize)
      setWindowPosition(savedPosition)
    } else {
      if (isMaximized) {
        setIsMaximized(false)
      } else {
        setSavedSize(windowSize)
        setSavedPosition(windowPosition)
      }
      setWindowSize({ width: 200, height: 34 })
      setWindowPosition({ x: 16, y: window.innerHeight - 100 })
    }
    setIsMinimized(!isMinimized)
  }

  const handleResize = (event, {node, size, handle}) => {
    setWindowSize({width: size.width, height: size.height})
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
        pointerEvents: "none",
      }}
      className={isMinimized ? "minimized" : ""}
    >
      <Draggable
        handle=".window-title"
        bounds="parent"
        // disabled={isMaximized} // Desativa o arrastar quando maximizado
        position={isMaximized ? { x: 0, y: 0 } : windowPosition} // Fixa a posição quando maximizado
        onStart={() => setIsDraging(true)}
        onStop={(e, data) => {
          setWindowPosition({ x: data.x, y: data.y }) // Atualiza a posição quando arrastado
          setIsDraging(false)
        }}
      >
        <Resizable
          minConstraints={[350, 350]}
          maxConstraints={isMaximized ? [window.innerWidth, window.innerHeight] : undefined}
          height={windowSize.height}
          width={windowSize.width}
          onResize={isMinimized ? null : handleResize}
          onResizeStart={() => setIsResizing(true)} // Inicia o redimensionamento
          onResizeStop={() => setIsResizing(false)} // Termina o redimensionamento
        >
          <Paper
            elevation={3}
            sx={{
              width: `${windowSize.width}px`,
              height: `${windowSize.height}px`,
              minWidth: isMinimized ? "217px" : "auto",
              pointerEvents: "auto",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <ThemeProvider theme={{...theme, ...darkTheme}}>
              <Box
                className={`window-title ${isMinimized ? "skeleton-wave" : ""}`}
                sx={{
                  pl: 1,
                  pr: 0.5,
                  cursor: isMaximized ? "default" : "move",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: darkTheme.palette.background.default,
                  color: darkTheme.palette.text.primary,
                }}
              >
                <Typography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Badge color="success" variant="dot" invisible={!isMinimized}>
                    <CameraIcon fontSize="small" />
                  </Badge>
                  <strong style={{ marginLeft: '8px' }}>{title}</strong>
                </Typography>
                <Box>
                  <IconButton
                    size="small"
                    onClick={toggleMinimize}
                  >
                    {isMinimized ? <WindowMaximizeIcon/> : <WindowMinimizeIcon />}
                  </IconButton>
                  {!isMinimized && (
                    <IconButton
                      size="small"
                      onClick={toggleMaximize}
                    >
                      {isMaximized ? <WindowRestoreIcon /> : <WindowMaximizeIcon />}
                    </IconButton>
                  )}
                  {!isMinimized && (
                    <IconButton
                      size="small"
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </ThemeProvider>
            <Box
              sx={{
                flexGrow: 1,
                // opacity: isMinimized ? 0 : 1,
                visibility: isMinimized ? 'hidden' : 'visible',
                pointerEvents: isResizing || isDraging ? 'none' : 'all'
              }}
            >
              {children}
            </Box>
          </Paper>
        </Resizable>
      </Draggable>
    </Box>
  )
}

export default FloatingWindow
