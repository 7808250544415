import axios from 'axios'
import store from '../store'
import { logout } from '../store/authSlice'


// URL base da API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL // 'http://localhost:3000/api'

// Cria uma instância do axios com configurações padrão
const apiMainClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000 // 30s
})

// Interceptor para adicionar o JWT ao cabeçalho de cada solicitação
apiMainClient.interceptors.request.use(config => {
  // Tenta obter o JWT do localStorage ou sessionStorage
  const jwtSession = sessionStorage.getItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`) // janela atual
  const jwtLocal = localStorage.getItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`) // qualquer janela

  const token = jwtSession || jwtLocal

  // Se o token estiver presente, adicione-o ao cabeçalho Authorization
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
}, error => {
  // Manipule o erro da solicitação
  return Promise.reject(error)
})

// Configuração adicional, como interceptadores, pode ser adicionada aqui
apiMainClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Lógica para tratamento global de erros
    if ('response' in error && 'data' in error.response && 'message' in error.response.data && error.response.data.message) {
      if (error.response.data.message === 'Invalid token.') {
        store.dispatch(logout())
      }
      const customError = new Error(error.response.data.message)
      // Adiciona o body completo da resposta ao erro
      customError.response = error.response.data // Body
      customError.status = error.response.status // Status Code

      return Promise.reject(customError)
    }

    return Promise.reject(error)
  }
)

export default apiMainClient
