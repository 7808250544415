import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    theme: localStorage.getItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}theme`) || 'light',
    menuOpen: true,
    userAlertsDialogOpen: false,
    menuSearchOpen: false,
    menuSearch: '',
    callUrl: null,
  },
  reducers: {
    toggleTheme: (state) => {
      const theme = state.theme === 'light' ? 'dark' : 'light'
      state.theme = theme
      localStorage.setItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}theme`, theme)
    },
    toggleMenu: (state) => {
      state.menuOpen = !state.menuOpen
    },
    openMenu: (state) => {
      state.menuOpen = true
    },
    closeMenu: (state) => {
      state.menuOpen = false
    },
    toggleUserAlertsDialog: (state) => {
      state.userAlertsDialogOpen = !state.userAlertsDialogOpen
    },
    openUserAlertsDialog: (state) => {
      state.userAlertsDialogOpen = true
    },
    closeUserAlertsDialog: (state) => {
      state.userAlertsDialogOpen = false
    },
    toggleMenuSearch: (state) => {
      state.menuSearchOpen = !state.menuSearchOpen
    },
    setMenuSearch: (state, action) => {
      state.menuSearch = action.payload
    },
    setCallUrl: (state, action) => {
      state.callUrl = action.payload
    },
  },
})

export const {
  toggleTheme,
  toggleMenu,
  openMenu,
  closeMenu,
  toggleUserAlertsDialog,
  openUserAlertsDialog,
  closeUserAlertsDialog,
  toggleMenuSearch,
  setMenuSearch,
  setCallUrl,
} = appSlice.actions

export default appSlice.reducer
