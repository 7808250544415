import { configureStore } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'
import appSlice from './appSlice'
import authReducer from './authSlice'
import countryReducer from './countrySlice'
import telemedicineSlice from './telemedicineSlice'

const store = configureStore({
  reducer: {
    app: appSlice,
    auth: authReducer,
    country: countryReducer,
    telemedicine: telemedicineSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

export default store
