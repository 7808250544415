import { createSlice } from '@reduxjs/toolkit'
import { getAllCountries } from '../services/countryService'

const initialState = {
  list: [],
  loading: false,
  error: null
}

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    requestStart(state) {
      state.loading = true
      state.error = null
    },
    requestSuccess(state, action) {
      state.loading = false
      state.list = action.payload
    },
    requestFailure(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { requestStart, requestSuccess, requestFailure } = countrySlice.actions
export default countrySlice.reducer

/**
 * Fabrica lista de todos os paises.
 */
export const fetchCountries = () => async (dispatch, getState) => {
  try {
    const state = getState()

    // carrega apenas uma vez
    if (state.country.loading) return
    if (state.country.list.length) return

    dispatch(requestStart())

    const response = await getAllCountries()

    dispatch(requestSuccess(response))

  } catch (error) {
    dispatch(requestFailure(error.message))
  }
}
