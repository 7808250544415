// Este arquivo contém funções para registrar e desregistrar o Service Worker

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] é o endereço IPv6 localhost.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 são considerados localhost para IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // O URL do Service Worker
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // O Service Worker não funcionará se PUBLIC_URL estiver em um domínio diferente.
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // Isso está rodando em localhost. Vamos verificar se o Service Worker ainda existe.
        checkValidServiceWorker(swUrl, config)

        // Adicione algum log adicional para localhost.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'Este aplicativo está sendo servido em cache-first por um service worker. ' +
              'Para saber mais, visite https://cra.link/PWA'
          )
        })
      } else {
        // Não é localhost. Apenas registre o Service Worker.
        registerValidSW(swUrl, config)
      }
    })
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // No caso de uma atualização, execute o callback onUpdate.
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // No caso de uma instalação inicial, execute o callback onSuccess.
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error('Erro durante o registro do Service Worker:', error)
    })
}

function checkValidServiceWorker(swUrl, config) {
  // Verifique se o Service Worker pode ser encontrado. Se não puder recarregar a página.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Certifique-se de que o Service Worker existe e que realmente estamos recebendo um arquivo JavaScript.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // Nenhum Service Worker encontrado. Provavelmente um aplicativo diferente. Recarregue a página.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service Worker encontrado. Prossiga com o registro.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log(
        'Nenhuma conexão de internet encontrada. O aplicativo está rodando no modo offline.'
      )
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}
