import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

function Loading({ title }) {

  const { t } = useTranslation()

  return(
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
      }}
    >
      <CircularProgress />
      <Typography
        variant="p"
        color="text.primary"
        pt={2}
      >
        {title ? title : t('carregando')} ...
      </Typography>
    </Box>
  )
}

export default Loading
