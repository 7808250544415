import apiClient from './apiMainClient'

/**
 * Obtém lista de todos os países.
 *
 * @returns {Promise<Object[]>} - Lista de paises.
 */
export const getAllCountries = async () => {
  try {
    const response = await apiClient.get('/countries/all')
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
