import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { SnackbarProvider } from './hooks/useSnackbar'
import store from './store'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// store
import { initializeAuthState } from './store/authSlice'

// Inicializa estado de autenticação para JWT existente no storage
store.dispatch(initializeAuthState())

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </Provider>
)
// <React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Registra o Service Worker
serviceWorkerRegistration.register()
