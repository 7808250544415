import { createSlice } from '@reduxjs/toolkit'
import { getTelemedicineCurrent } from '../services/telemedicineService'

const initialState = {
  /*{
      "_id": "66fc44598e99dd13675a31aa",
      "name": "Cetil",
      "logoLightTheme": {
        "bucket": "ms_public_dev",
        "path": "telemedicine_66fc44598e99dd13675a31aa/telemedicine_logo_theme/f9e47872-12eb-47be-83de-240a37cd8b01.png",
        "privateUrl": "https://storage.googleapis.com/download/storage/v1/b/ms_public_dev/o/telemedicine_66fc44598e99dd13675a31aa%2Ftelemedicine_logo_theme%2Ff9e47872-12eb-47be-83de-240a37cd8b01.png?generation=1727808603386098&alt=media",
        "publicUrl": "https://storage.googleapis.com/ms_public_dev/telemedicine_66fc44598e99dd13675a31aa/telemedicine_logo_theme/f9e47872-12eb-47be-83de-240a37cd8b01.png",
        ...
      },
      "logoDarkTheme": {
        "bucket": "ms_public_dev",
        "path": "telemedicine_66fc44598e99dd13675a31aa/telemedicine_logo_theme/60ae52fa-54d0-4325-b3b9-adfec0e0eb5d.png",
        "privateUrl": "https://storage.googleapis.com/download/storage/v1/b/ms_public_dev/o/telemedicine_66fc44598e99dd13675a31aa%2Ftelemedicine_logo_theme%2F60ae52fa-54d0-4325-b3b9-adfec0e0eb5d.png?generation=1727808603760620&alt=media",
        "publicUrl": "https://storage.googleapis.com/ms_public_dev/telemedicine_66fc44598e99dd13675a31aa/telemedicine_logo_theme/60ae52fa-54d0-4325-b3b9-adfec0e0eb5d.png",
        ...
      },
      "colorPrimaryLightTheme": "",
      "colorPrimaryDarkTheme": "",
      "hasConsultation": true,
      "hasDiagnostic": false
    }
   */
  data: null,
  loading: false,
  error: null,
}

const telemedicineSlice = createSlice({
  name: 'telemedicine',
  initialState,
  reducers: {
    requestStart(state) {
      state.loading = true
      state.error = null
    },
    requestSuccess(state, action) {
      state.loading = false
      state.data = action.payload
      // Atualiza HTML title
      document.title = action?.payload?.name || 'Medicsystems'
      // Atualiza favicon
      const faviconUrl = action?.payload?.faviconTheme?.publicUrl || '/favicon.ico'
      if (faviconUrl) changeFavicon(faviconUrl)
    },
    requestFailure(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { requestStart, requestSuccess, requestFailure } = telemedicineSlice.actions
export default telemedicineSlice.reducer

/**
 * Realiza login com as credenciais.
 *
 * @param {Object} credentials Objeto contendo 'email' e 'password'
 * @returns
 */
 export const fetchTelemedicine = () => async (dispatch) => {
  dispatch(requestStart())
  try {
    const response = await getTelemedicineCurrent()
    dispatch(requestSuccess(response))

  } catch (error) {
    dispatch(requestFailure(error.message))
  }
}

function changeFavicon(src) {
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = src
  document.getElementsByTagName('head')[0].appendChild(link)
}